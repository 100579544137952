/* eslint-disable ember/no-runloop */
import type { CustomerAPIResources } from '@clark-customer/entities/api-resources';
import type { ReferralCampaignData } from '@clark-customer/services/services/customer';
import { makeReferralCampaignResource } from '@clark-home/ui/resources/referral-campaign';
import type {
  AllPlugins,
  OptionalPlugins,
  PlatformService,
} from '@clark-shell/ember';
import { plugin, runAutoInit } from '@clark-shell/ember';
import type {
  Experiment,
  ExperimentsConfiguration,
  Feature,
  FeaturesConfiguration,
  SettingsConfiguration,
  Variant,
} from '@clark-utils/business-de/types';
import type { Query } from '@clark-utils/ember-query';
import type { BrandConfig, LegalDocument } from '@clark-utils/enums-and-types';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import { addListener } from '@ember/object/events';
import { getOwner } from '@ember/owner';
import Route from '@ember/routing/route';
import { schedule } from '@ember/runloop';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { captureException } from '@sentry/browser';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'embe... Remove this comment to see the full error message
import resolveAsset from 'ember-cli-resolve-asset';
import { restartableTask, task } from 'ember-concurrency';
import type RoutingUtilsService from 'ember-routing-utils/services/routing-utils';
import fetch from 'fetch';

import type ShellSettingsUserTrackingService from '../services/shell/settings/user-tracking';
import { updatedConsentCookie } from '../utils/components/cookie-banner';
import type { ConsentCookie } from '../utils/cookie';
import { getCookieValueAsJson } from '../utils/cookie';

// An Ember event emitted on the experiments service
type DetermineVariantEvent = {
  experiment: Experiment;
  name: 'determine-variant';
  variant: Variant<Experiment>;
};

export default class ApplicationRoute extends Route {
  @service declare notificationBanners: Services['notification-banners'];
  @service declare api: Services['api'];
  @service declare census: Services['census'];
  @service declare clarkVersion: Services['clark-version'];
  @service declare config: Services['config'];
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];
  @service declare firestarter: Services['firestarter'];
  @service declare intl: Services['intl'];
  @service declare legal: Services['legal'];
  @service declare router: Services['router'];
  @service declare routingUtils: RoutingUtilsService;
  @service declare session: Services['session'];
  @service declare settings: Services['settings'];
  @service declare tracking: Services['tracking'];
  @service declare updateNativeApp: Services['update-native-app'];
  @service declare zendeskMessenger: Services['zendesk-messenger'];
  @service declare authEventManager: Services['auth-event-manager'];
  @service('shell/settings/user-tracking')
  declare userTracking: ShellSettingsUserTrackingService;
  @plugin('Auth') declare auth: AllPlugins['Auth'];
  @service('shell/platform') declare platform: PlatformService;

  @plugin({ optional: true })
  private declare readonly splash: OptionalPlugins['Splash'];

  @service declare query: Services['query'];

  async beforeModel() {
    await this.configure();

    // @ts-expect-error: Property 'on' does not exist on type 'SessionService'
    this.session.on(
      'authenticationSucceeded',
      this.onAfterSuccessfulLogin.bind(this),
    );

    await Promise.all([this.loadTranslations('de-de'), this.boot()]);

    // for new code added in this function, which doesn't happen by the time of the configureExperiments call.
    this.configureExperimentsToBackend();

    // ! This will fire non-GET requests, that require a valid CSRF token.
    // Therefore this MUST be called after the insane token negotiation further
    // above.
    // @ts-expect-error: Argument of type 'Owner | undefined' is not assignable to parameter of type 'EngineInstance'
    await runAutoInit(getOwner(this));
  }

  private configureApiEvents() {
    // @ts-expect-error: Property 'on' does not exist on type 'ApiService'
    this.api.on('onUnauthenticated', () => {
      // We want to make sure to intercept 401 requests after the user logged in
      if (this.session.isAuthenticated) {
        this.authEventManager.didLogout();
        this.router.replaceWith('login');
        this.notificationBanners.show(
          this.intl.t('api-error-page.session-expired.message'),
          {
            appearance: 'success',
          },
        );

        // in the case we do not need to confirm the logout
        // we will just call update credentials right away
        // with undefined as we are logging out
        if (this.platform.isNative) {
          this.auth.setCredentials({ credentials: undefined });
        }
      }
    });
  }

  async onListenOneTrust(e: any) {
    console.log(e, 'OTUpdate');
    if (e.data) {
      const { attributes: consent } = JSON.parse(e.data);
      const _updatedConsentCookie = updatedConsentCookie(consent);
      document.cookie = _updatedConsentCookie;
      if (this.session.isAuthenticated) {
        await this.saveMandateConsentToApi.perform(consent);
      }
    }
  }

  async syncCookies() {
    const consent: ConsentCookie = getCookieValueAsJson(
      document.cookie,
      'consent',
    );
    await this.saveMandateConsentToApi.perform(consent);
  }

  saveMandateConsentToApi = task(async (consent) => {
    try {
      await this.api.post(
        'customer/current/consent',
        { data: { type: 'consent', attributes: consent } },
        { version: 'v5' },
      );
    } catch (exception: unknown) {
      captureException(exception);
    }
  });

  get shouldShowOneTrustBanner(): boolean {
    return this.features.isEnabled('ONE_TRUST_BANNER');
  }

  get referralCampaignResource(): Query<{ data: ReferralCampaignData }> {
    return makeReferralCampaignResource(this.api, this.query);
  }

  async boot() {
    if (this.firestarter.isEnabled) {
      const { csrfToken, clarkVersion } = await this.firestarter.payload;

      this.api._token = csrfToken;
      this.clarkVersion.setClarkVersion(clarkVersion);
      this.setLegacyClarkVersionAsExperiment();
    }

    /**
     * CAUTION: Do not try to further optimize this code by initiating the
     * token request after *any* API request has succeeded. While the token
     * is being loaded, there *must not* be any other API request in flight,
     * as this might create a new session and invalidate the token.
     */
    await Promise.all([
      this.loadFeatures.perform(),
      this.loadSettings.perform(),
    ]);

    if (!this.firestarter.isEnabled) {
      /*
        CAUTION: The CSRF token *has* to be loaded after at least one XHR has
        gone through, because in doing a request, we create a new session, but
        this session only takes effect *after* the request that created it has
        gone through. This means that whenever the token request is the first
        request, it would return a token that would be immediately invalidated,
        because a new session is created by requesting the token.
        WTF! Can we *please* switch to JWT already?
      */
      await this.api.refreshToken.perform();
    }

    // @TODO replace with `matchbox.authenticationState`
    this.session.checkAuthentication.perform();

    if (!this.firestarter.isEnabled) {
      await this.setupClarkVersion();
      this.setLegacyClarkVersionAsExperiment();
    }
  }

  async configure() {
    this.configureTracking();
    await this.configureExperiments();
    this.configureLegal();
    this.configureCensus();
    this.configureApiEvents();
  }

  /**
   * Initializes `@clark-shell/tracking`.
   *
   * @see TrackingService
   * @see https://github.com/ClarkSource/application/tree/master/client-support/utils/tracking.js#Configuration
   */
  private configureTracking() {
    const countryCode = this.config.getConfig(
      'locale.countryCode',
    ) as BrandConfig['locale']['countryCode'];

    this.tracking.countryCode = countryCode.toUpperCase() as Uppercase<
      typeof countryCode
    >;
  }

  private async mergeExperimentConfigs() {
    // load experiments from local config
    const localConfig = (this.config.getConfig('experiments') ??
      {}) as ExperimentsConfiguration<Experiment>;

    const backendExperimentsConfig = (await this.api.get('experiments/config', {
      version: 'v5',
    })) as ExperimentsConfiguration<Experiment>;

    const config = { ...localConfig, ...backendExperimentsConfig };

    // This is so that the new experiments config is reflected in the FE config
    this.config.currentConfig.experiments = config;

    this.experiments.configure(config);
  }

  async configureExperiments() {
    // load experiments from config
    await this.mergeExperimentConfigs();

    // listen when variant is determined and fire tracking event
    addListener(
      this.experiments,
      'determine-variant',
      (event: DetermineVariantEvent) => {
        const { experiment, variant } = event;

        this.tracking.track('utils/experiments:determine-variant', {
          experiment,
          variant,
        });
      },
    );
  }

  private async configureExperimentsToBackend() {
    const backendExperimentsConfig = this.config.getConfigWithDefault(
      'experimentsSetByBackend',
      [],
    ) as Experiment;

    addListener(
      this.experiments,
      'determine-variant',
      (event: DetermineVariantEvent) => {
        const { experiment, variant } = event;
        if (backendExperimentsConfig.includes(experiment)) {
          this.setExperimentsInBackend(experiment, variant);
        }
      },
    );
  }

  private async onAfterSuccessfulLogin() {
    this.configureExperimentsFromBackend();
    this.referralCampaignResource.fetch();

    await this.zendeskMessenger.initialiseMessenger();
  }

  private async configureExperimentsFromBackend() {
    // Call BE API for list of experiments for the user
    const experimentsList = await this.api.get('experiments/variants', {
      version: 'v5',
    });

    // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'Response'.
    for (const experiment of experimentsList.data) {
      const { experiment_name: experimentName, variant } =
        experiment.attributes;
      this.experiments.forceVariant(experimentName, variant);
    }
  }

  private async setExperimentsInBackend(
    experimentName: string,
    variant: string,
  ) {
    await this.api.post(
      `experiments/variants`,
      {
        experiment_name: experimentName,
        variant,
      },
      { version: 'v5' },
    );
  }

  configureLegal() {
    const documents: Partial<Record<LegalDocument, string>> = {};
    const legal = (this.config.getConfig('legal') ??
      {}) as BrandConfig['legal'];

    for (const [key, value] of Object.entries(legal)) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      documents[key] = value.url;
    }

    this.legal.setDocumentURLs(documents);
  }

  configureCensus() {
    this.census.configure({
      apiClient: this.api,
    });
  }

  setLegacyClarkVersionAsExperiment() {
    this.experiments.configureExperiment('business-strategy', {
      control: 1,
      clark2: 0,
    });
    this.experiments.forceVariant(
      'business-strategy',
      this.clarkVersion.isClark2 ? 'clark2' : 'control',
    );
  }

  async setupClarkVersion() {
    this.clarkVersion.setup({
      settings: {
        clark2: Boolean(this.settings.getSetting('clark2')),
      },
      customer: await retryOnNetworkFailure(() =>
        this.api.get<CustomerAPIResources['customer/current']>(
          'customer/current',
          { deserializeJSONAPI: true },
        ),
      ).catch(() => undefined),
    });
  }

  afterModel() {
    if (this.splash) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      // eslint-disable-next-line ember/no-runloop
      schedule('afterRender', () => this.splash.hide());
    }
    if (this.shouldShowOneTrustBanner) {
      // eslint-disable-next-line ember/no-runloop
      schedule('afterRender', () => {
        this.userTracking.loadClarkTrackingScript();
        window.addEventListener('OTUpdate', this.onListenOneTrust.bind(this));

        // @ts-expect-error: Property 'on' does not exist on type 'SessionService'
        this.session.on('authenticationSucceeded', this.syncCookies.bind(this));
        // @ts-expect-error: Property 'on' does not exist on type 'SessionService'
        this.session.on('mandateCreated', this.syncCookies.bind(this));
      });
    }
  }

  async redirect() {
    // check for update and redirect respectively
    if (this.updateNativeApp.isUpdateAvailable) {
      return this.router.replaceWith('update-native-app');
    }

    if (this.firestarter.isEnabled) {
      const { redirect } = await this.firestarter.payload;

      if (redirect) {
        return this.router.replaceWith(
          this.routingUtils.removeRootURL(redirect),
        );
      }
    }
  }

  private loadFeatures = restartableTask(async () => {
    const configuration =
      await this.api.get<FeaturesConfiguration<Feature>>('features');

    this.features.configure(configuration);
  });

  private loadSettings = restartableTask(async () => {
    const configuration = await this.api.get<SettingsConfiguration>('settings');

    this.settings.configure(configuration);
  });

  private async loadTranslations(locale: 'de-de'): Promise<void> {
    const translationsURL = await resolveAsset(`translations/${locale}.json`);

    const response = await fetch(translationsURL);
    const translations = await response.json();

    this.intl.addTranslations(locale, translations);
    this.intl.setLocale(locale);
  }
}
